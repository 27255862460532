import React from 'react'
import './tile.css'

export default function Tile(props) {
    return (
        <div className="tile">
            <span className="tile-number">{props.title}</span>
            <p className="tile-text">{props.label}</p>
        </div>
    )
}

import React from 'react'
import {Helmet} from 'react-helmet'
import Layout from '../components/layout'
import ModalImage from 'react-modal-image-responsive'
import './citi-velocity.css'
import Tile from '../components/tile/tile'
import CVCompanySignalOverview from '../images/CitiVelocity/Company Signal Overview.png'
import CVCompanySignalMarkets from '../images/CitiVelocity/Company Signal Markets.png'
import CVChartingCurve from '../images/CitiVelocity/Charting/Curve With Regression.png'
import CVContentGroup from '../images/CitiVelocity/Content Group.png'
import CVOldMarketBuzz from '../images/CitiVelocity/Old Market Buzz.png'
import ICGDSButtonsDark from '../images/ICGDS/ICGDS Button.png'
import ICGDSButtonsLight from '../images/ICGDS/ICGDS Button Light.png'


export default function CitiVelocity() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Leland Riordan - Portfolio - ICG Design System</title>
      </Helmet>
      <div className="case-study-feature">
        <img src={CVCompanySignalMarkets} className="case-study-feature-image" alt='Citi Velocity'/>
      </div>

      <div className="main-content case-study-content">
        <div className="row row-max">
          <h1 className="case-study-title">ICG Design System</h1>
          <p className="lead">
            From humble beginnings as a small React library of shared components for Citi Velocity, I helped launch ICG Design system into the standard design system across all products at Citi.
          </p>
        </div>

        <div id="introduction" className="row row-max">
          <h2>Introduction</h2>

          <p>
            When I joined the firm, Citi Velocity looked like what it was, a patchwork quilt of mismatched applications and content from different businesses within Citi.
            Hundreds of different teams develop applications on the platform, each with its own stakeholders, timelines, tech stacks and goals.
            Unifying these disperate groups would be daunting - the only way to start was from the ground up with a standard design system.
          </p>

        </div>

        <div id="myRole" className="row row-max">
          <h2>My Role</h2>
          <p>
            Upon my hiring, I inherited a small React library that had been rolled out to the core analytics pages on Citi Velocity called Elemental.
            I immeadiately had my team get to work on rapidly turning it into a true design system.
            I assigned my top lieutenant to be its leader and together we rapidly scaffolded out a prototype.
            I sat on the trading floor and utilized my location to gather immeadiate user feedback as we went.
          </p>
          <p>
            Soon we had an MVP library of components and demo pages built in Sketch that I used to gather buy in from the other design leaders at the firm.
            One of those leaders, the head of design for global spread products, was working on a similar project.
            We combined our resources, changed the name to ICG Design System and successfully pitched the executive team for funding.
            Soon we had developer teams building Angular and React libraries of the components.
            Fast forward to now and ICG Design System has been set as the standard DS for all products at the firm.
            It has grown to include support dark and light themes as well as third party libraries such as AG Grid, Chart.js and High Charts.
            We hope to open source the system in the future if we can get passed the myriad of hurdles from the legal team.
          </p>
        </div>

        <div id="introduction" className="row row-max">
          <h2></h2>
        </div>

        <div className="figure-container">
          <figure>
            <figcaption>Old Citi Velocity Homepage Circa 2019</figcaption>
            <ModalImage
              small={CVOldMarketBuzz}
              large={CVOldMarketBuzz}
              className="case-study-image"
              hideDownload={true}
              hideZoom={true}
              alt="Old Citi Velocity Homepage Circa 2019"
            />
          </figure>
        </div>



        <div className="row row-max">
          <h2>Outcome</h2>
          <p>
            We took a careful measured approach to the rollout of the Citi Velocity redesign by releasing it in phases and permissioning groups of users over time with feature flags.
            At each step we analyzed usage analytics, gathered user feedback and made final refinements.
            The first phase was fully released to clients in summer of 2021 followed closely by phase 2 in January 2022.
          </p>
          <p>
            The redesign was a resounding success.
            We beat almost all of our target metrics including +25% stickiness growth while driving our net promoter score to 8+.
            Our incremental enhancements to charting improved its discoverability and we won three straight Best Analytics Platform on Wall Awards from Institutional Investor magazine.
            I am proud of what my team and I have been able to accomplish given the limited resources, the mountain of corporate bureaucracy we helped conquer and the fact that we did it all remotely during the height of the COVID-19 pandemic.
          </p>
          {/* <p>
            The one thing I would do differently in the future is take harder stands and compromise less. While the redesign exceeded all of my initial expectations I feel as we made a few too many compromises for arbirtr
          </p> */}

          {/* <p>
            In retrospect, the main thing that I would have done differently is redesign the mobile app experience in concert with the web version.
            While historically the mobile version of Citi Velocity was only used by a subset of users, the COVID-19 pandemic caused usage to grow by 100% almost overnight.
            While we were able to update the styling and a few key elements, we just didnt have the resources to do both redesigns at the same time or to change priorities midway through the redesign process.
            Redesigning the mobile experience has been made a top priority for 2022 and my team and I have been working on early protoypes.
          </p> */}

        </div>
      </div>

    </Layout>
  )
}
